import React, { useRef, useContext }  from 'react';
import styles from '../styles/Content03_M.module.css';
import Image01 from '../../assets/img/content03-01-m.png';
import Image02 from '../../assets/img/content03-02-m.png';
import Image03 from '../../assets/img/content03-03-m.png';
import Image04 from '../../assets/img/content03-04-m.png';
import { UserDefaultVal, MobileDefaultVal } from '../../UserContextVal';


const Content03_M = React.forwardRef(({}, ref:any) => {
  const { t, i18n } = useContext(UserDefaultVal);
  const { playAniClass, ratioVal } = useContext(MobileDefaultVal);

  const ctnElmH1_01:any = useRef();
  const ctnElmH1_02:any = useRef();
  const ctnElm01:any = useRef();
  const ctnElm02:any = useRef();
  const ctnElm03:any = useRef();
  const ctnElm04:any = useRef();

  let ctnElm01_img_trans = false;
  let ctnElm02_img_trans = false;
  let ctnElm03_img_trans = false;
  let ctnElm04_img_trans = false;

  if(ratioVal >= 23 && ratioVal < 26) {
    ctnElm01_img_trans = true;
  } else {
    ctnElm01_img_trans = false;
  };

  if(ratioVal >= 29.5 && ratioVal < 32.5) {
    ctnElm02_img_trans = true;
  } else {
    ctnElm02_img_trans = false;
  };

  if(ratioVal >= 41 && ratioVal < 44) {
    ctnElm03_img_trans = true;
  } else {
    ctnElm03_img_trans = false;
  };

  if(ratioVal >= 47.5 && ratioVal < 50.5) {
    ctnElm04_img_trans = true;
  } else {
    ctnElm04_img_trans = false;
  };


  return (
    <div 
      className={styles.container}
      ref={ref}
    >
      <div className={styles.innerCont}>
        {/* title 01 */}
        <div
          className={`${styles.title01} ${playAniClass(ctnElmH1_01) ? styles.ctnElmH1_01 : ''}`} 
          ref={ctnElmH1_01} 
        >
          {i18n.language === 'ko' ?
            <>
              <h3 className={styles.cont03_H1_01}>
                {t('cont03_title_01_01')}
              </h3>
              <h3 className={styles.cont03_H1_02}>
                <span className={styles.pointText}>
                  {t('cont03_title_01_02')}
                </span>
                {t('cont03_title_01_03')}
              </h3>
              <h3 className={styles.cont03_H1_03}>
                {t('cont03_title_01_04')}
              </h3>
            </>
            :
            <>
              <h3 className={styles.cont03_H1_01}>
                {t('cont03_title_01_01')}
              </h3>
              <h3 className={styles.cont03_H1_02}>
                {t('cont03_title_01_02')}
                {t('cont03_title_01_03')}
              </h3>
              <h3 className={styles.cont03_H1_03}>
                {t('cont03_title_01_04')}
                <span className={styles.pointText}>
                  {t('cont03_title_01_05')}
                </span>
                {t('cont03_title_01_06')}
              </h3>
            </>
          }
        </div>
        {/* 01 */}
        <div 
          className={`${styles.content01} ${playAniClass(ctnElm01) ? styles.ctnElm01 : ''}`}
          ref={ctnElm01}
        >
          <div 
            className={`${styles.imgWrap} ${ctnElm01_img_trans ? styles.ctnElm01_img : ''}`}
          >
            <div className={styles.hover}>
              <p>{t('cont03_hover_01_01')}</p>
              <p>{t('cont03_hover_01_02')}</p>
              <p>{t('cont03_hover_01_03')}</p>
              <p>{t('cont03_hover_01_04')}</p>
            </div>
            <div
              className={styles.imgTrans}
            >
              <img src={Image01} alt="이미지" />
            </div>
          </div>
          <div
            className={styles.textWrap}
          >
            <h3>
              <span className={styles.pointText}>
                AI
              </span>
              Remastering
            </h3>
            <p>{t('cont03_desc_01')}</p>
          </div>
        </div>
        {/* 02 */}
        <div 
          className={`${styles.content02} ${playAniClass(ctnElm02) ? styles.ctnElm02 : ''}`}
          ref={ctnElm02}
        >
          <div className={`${styles.imgWrap} ${ctnElm02_img_trans ? styles.ctnElm02_img : ''}`}>
            <div className={styles.hover}>
              <p>{t('cont03_hover_02_01')}</p>
              <p>{t('cont03_hover_02_02')}</p>
              <p>{t('cont03_hover_02_03')}</p>
            </div>
            <div
              className={styles.imgTrans}
            >
              <img src={Image02} alt="이미지" />
            </div>
          </div>
          <div
            className={styles.textWrap}
          >
            <h3>
              <span className={styles.pointText}>
                AI
              </span>
              DeOldify
            </h3>
            <p>{t('cont03_desc_02')}</p>
          </div>
        </div>
        {/* title 02 */}
        <div 
          className={`${styles.title02} ${playAniClass(ctnElmH1_02) ? styles.ctnElmH1_02 : ''}`} 
          ref={ctnElmH1_02} 
        >
          {i18n.language === 'ko' ?
            <>
              <h2 className={styles.cont03_H1_01}>
                {t('cont03_title_02_01')}
              </h2>
              <h2 className={styles.cont03_H1_02}>
                {t('cont03_title_02_02')}
                {t('cont03_title_02_03')}
              </h2>
              <h2 className={styles.cont03_H1_03}>
                {t('cont03_title_02_04')}
              </h2>
            </>
            :
            <>
              <h2 className={styles.cont03_H1_01}>
                {t('cont03_title_02_01_m')}
              </h2>
              <h2 className={styles.cont03_H1_02}>
                {t('cont03_title_02_02_m')}
              </h2>
            </>
          }
        </div>
        {/* 03 */}
        <div 
          className={`${styles.content03} ${playAniClass(ctnElm03) ? styles.ctnElm03 : ''}`}
          ref={ctnElm03}
        >
          <div className={`${styles.imgWrap} ${ctnElm03_img_trans ? styles.ctnElm03_img : ''}`}>
            <div className={styles.hover}>
              <p>{t('cont03_hover_03_01')}</p>
              <p>{t('cont03_hover_03_02')}</p>
              <p>{t('cont03_hover_03_03')}</p>
            </div>
            <div
              className={styles.imgTrans}
            >
              <img src={Image03} alt="이미지" />
            </div>
          </div>
          <div
            className={styles.textWrap}
          >
            <h3>
              <span className={styles.pointText}>
                AI
              </span>
              Security
            </h3>
            <p className={styles.exceptionText}>{t('cont03_desc_03')}</p>
          </div>
        </div>
        {/* 04 */}
        <div 
          className={`${styles.content04} ${playAniClass(ctnElm04) ? styles.ctnElm04 : ''}`}
          ref={ctnElm04}
        >
          <div className={`${styles.imgWrap} ${ctnElm04_img_trans ? styles.ctnElm04_img : ''}`}>
            <div className={styles.hover}>
              <p>{t('cont03_hover_04_01')}</p>
              <p>{t('cont03_hover_04_02')}</p>
              <p>{t('cont03_hover_04_03')}</p>
            </div>
            <div
              className={styles.imgTrans}
            >
              <img src={Image04} alt="이미지" />
            </div>
          </div>
          <div
            className={styles.textWrap}
          >
            <h3>
              <span className={styles.pointText}>
                AI
              </span>
              Factory
            </h3>
            <p>{t('cont03_desc_04')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}) 

export default Content03_M;