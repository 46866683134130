import React from "react";
import { Route, Routes } from 'react-router-dom';
import App from "./App";
import HttpError from './common/component/HttpError';


const Router = () => {

    return (
        <Routes>
            <Route path="*" element={<HttpError />} />
            <Route path="/" element={<App />} />
        </Routes>
    );
}

export default Router;
