import React, { useState, useMemo, useEffect, useCallback }  from 'react';
import styles from '../styles/MainPC.module.css';
import Nav_PC from './Nav_PC';
import Menu_PC from './Menu_PC';
import Content01 from './Content01';
import Content02 from './Content02';
import Content03 from './Content03';
import Content04 from './Content04';
import Content05 from './Content05';
import Content06 from './Content06';
import { PcDefaultVal } from '../../UserContextVal';

function MainPC() {
  const [wheelOn, setWheelOn] = useState(0);
  const [isWheel, setIsWheel] = useState(true);
  const [wheelVal, setWheelVal]:any = useState(1);
  const [ratioVal, setRatioVal]:any = useState(0);
  const [totalW, setTotalW]:any = useState(0);
  const [menuOnOff, setMenuOnOff]:any = useState(false);
  const [isPlayer, setIsPlayer] = useState(false); 
  const [contentInfo, setContentInfo]:any = useState ([
    //Global Nav
    {
      type: 'normal',
      contentWidth: 0,
      contentRatio: 1,
      values: {
        contentAni01: [0 , 1, { start: 7.6, end: 7.7 }],
        contentAni02: [0 , 1, { start: 23.4, end: 23.5 }],
        contentAni03: [0 , 1, { start: 72.9, end: 73 }],
      },
    },
    //1
    {
      type: 'normal',
      contentWidth: 0,
      contentRatio: 1,
      values: {
        contentAni01: [0 , 1, { start: 0, end: 16 }],
      },
    },
    //2
    {
      type: 'wheel',
      contentWidth: 0,
      contentRatio: 1,
      values: {
        contentAni01_h1: [0 , 1, { start: 3, end: 12 }],
        contentAni02_h1: [0 , 1, { start: 7.48, end: 13.7 }],
        contentAni01_t: [0 , 1, { start: 11.5, end: 15.7 }],
        contentAni01_img_opa: [0 , 1, { start: 7, end: 15.7 }],
      },
    },
    //3
    {
      type: 'wheel',
      contentWidth: 0,
      contentRatio: 2.1677,
      values: {
        contentAni01_h1: [0 , 1, { start: 19.44, end: 27 }],
        contentAni01_2_h1: [0 , 1, { start: 23.94, end: 31 }],
        contentAni01_opc: [0 , 1, { start: 28.3, end: 34.3 }],
        contentAni01_m: [1 , 0, { start: 28.3, end: 31 }],
        contentAni02_opc: [0 , 1, { start: 29.8, end: 36 }],
        contentAni02_m: [1 , 0, { start: 29.8, end: 35.6 }],
        contentAni02_h1: [0 , 1, { start: 36, end: 41 }],
        contentAni02_2_h1: [0 , 1, { start: 38.42, end: 43 }],
        contentAni03_opc: [0 , 1, { start: 43.64, end: 49.64 }],
        contentAni03_m: [1 , 0, { start: 43.64, end: 46.34 }],
        contentAni04_opc: [0 , 1, { start: 45.14, end: 51.34 }],
        contentAni04_m: [1 , 0, { start: 45.14, end: 50.94 }],
      },
    },
    //4
    {
      type: 'wheel',
      contentWidth: 0,
      contentRatio: 1,
      values: {
        contentAni01_t: [0 , 1, { start: 63.4, end: 65 }],
      },
    },
    //5
    {
      type: 'wheel',
      contentWidth: 0,
      contentRatio: 1,
      values: {
        contentAni01_h1: [0 , 1, { start: 71.66, end: 80.74 }],
        contentAni01_t: [0 , 1, { start: 74.6, end: 80.74 }],
      },
    },
    //6
    {
      type: 'wheel',
      contentWidth: 0,
      contentRatio: 1.234,
      values: {
        contentAni01_img_sc: [0.3 , 0, { start: 85.12, end: 96 }],
        contentAni01_img_m: [1 , 0, { start: 85.12, end: 96 }],
        contentAni01_img_opc: [0 , 1, { start: 86.5, end: 98.5 }],
        contentAni01_t_m: [1 , 0, { start: 88.51, end: 97.26 }],
        contentAni01_t: [0 , 1, { start: 89.3, end: 97.8 }],
        contentAni01_2_t: [0 , 1, { start: 91, end: 98.74 }],
        contentAni02_img_sc: [0.3 , 0, { start: 89.38, end: 100 }],
        contentAni02_img_m: [1 , 0, { start: 89.38, end: 100 }],
        contentAni02_img_opc: [0 , 1, { start: 90.31, end: 100 }],
        contentAni02_t_m: [1 , 0, { start: 92.77, end: 100 }],
        contentAni02_t: [0 , 1, { start: 93.56, end: 100 }],
        contentAni02_2_t: [0 , 1, { start: 94.53, end: 100 }],
        contentAni03_ico: [0 , 1, { start: 98, end: 98.1 }],
      },
    },                    
  ]);

  const setLayout = () => {
    setContentInfo((current:any) => {
      for(let i = 0; i < contentInfo.length; i++){
        if (window.innerWidth >= 1240 && window.innerWidth <= 2560) {
          contentInfo[i].contentWidth = contentInfo[i].contentRatio * window.innerWidth;
        } else if (window.innerWidth < 1240) {
          contentInfo[i].contentWidth = contentInfo[i].contentRatio * 1240;
        } else if (window.innerWidth > 2560) {
          contentInfo[i].contentWidth = contentInfo[i].contentRatio * 2560;
        }
      }
      return current;
    });

    setTotalW((current:any) => {
      for(let i = 0; i < contentInfo.length; i++){
        if(contentInfo[i].type === 'wheel'){
          current += contentInfo[i].contentWidth;
        };
      }
      return current;
    });
  }
  

  const playAnimation = (values:any) => {
    const partScrollStart = values[2].start;
    const partScrollEnd = values[2].end;
    const partScrollHeight = partScrollEnd - partScrollStart;
  
    let returnVal;
    if (ratioVal >= partScrollStart && ratioVal <= partScrollEnd) {
      returnVal = (ratioVal - partScrollStart) / partScrollHeight * (values[1] - values[0]) + values[0];
    } else if (ratioVal < partScrollStart) {
      returnVal = values[0];
    } else if (ratioVal > partScrollEnd) {
      returnVal = values[1];
    }
    return returnVal;
  }

  const setWheel = (e:any) => {
    // wheelOn
    if(isWheel) {
      if(wheelOn < -(window.innerWidth / 2)) {
        setIsWheel(current => current = false);
      } else {
        setWheelOn( (current:any) => {
          current = Math.min(Math.max(-(totalW), current += e.deltaY * -1), 0);
          return current;
        });
      }
      // 스크롤 0
    } else if(!isWheel) {
      // 스크롤 ++
      setWheelVal( (current:any) => {
        current = Math.min(Math.max(-(totalW), current += e.deltaY * -1), 0);
        return current;
      });
  
      setRatioVal( (current:any) => {
        current = Number(((-wheelVal / (totalW)) * 100).toFixed(2));
        return current;
      });
    }

  };



  const resetFtn = () => {
    setTotalW( (current:any) => current = 0);
    setWheelVal( (current:any) => current = 0);
    setRatioVal( (current:any) => current = 0);
    setLayout();
  }

  const menuFtn = () => {
    setMenuOnOff((current:any) => current = ! current);
  }

  const movePos = (target: string) => {
    let endP:any;
    if(target === 'main' || target === 'goMain') {
     endP = 0;
    } else if (target === 'about') {
      endP = -contentInfo[1].contentWidth;
    } else if (target === 'reference') {
      endP = -(contentInfo[1].contentWidth + contentInfo[2].contentWidth + contentInfo[3].contentWidth + contentInfo[4].contentWidth);
    } else if (target === 'exprience') {
      endP = -(contentInfo[1].contentWidth + contentInfo[2].contentWidth + contentInfo[3].contentWidth + contentInfo[4].contentWidth + contentInfo[5].contentWidth);
    };
    let rafId;
    let xPos: any;
    if (wheelVal > endP) {
      xPos = -wheelVal;
    } else if (wheelVal < endP) {
      xPos = wheelVal;
    }

    const render = () => {
      rafId = requestAnimationFrame(render);
      if (wheelVal > endP) {
        // S -> E
        xPos += (wheelVal - endP) / 30;
        setWheelVal((current: number) => current = -xPos);
        setRatioVal( (current:any) => current = Number(((xPos / (totalW)) * 100).toFixed(2)));
        if (-xPos < endP) {
          setWheelVal((current: number) => current = endP);
          cancelAnimationFrame(rafId);
        }
      } else if (-xPos > endP) {
        // E -> S
        xPos += (endP - wheelVal) / 30;
        setWheelVal((current: number) => current = xPos);
        setRatioVal( (current:any) => current = Number(((-xPos / (totalW)) * 100).toFixed(2)));
        if (-xPos < -endP) {
          setWheelVal((current: number) => current = endP);
          cancelAnimationFrame(rafId);
        }
      }
    }
    render();

    if (target !== 'goMain') {
      // 메뉴 닫기 
      setTimeout(() => {
        menuFtn();
      }, 400)
    }

  }

  const [isDrag, setIsDrag] = useState(false);
  const [dragVal, setDragVal] = useState({mx: 0, cx: wheelVal});

  const onDragStart = (e:any) => {
    e.preventDefault()
    setIsDrag(true);
    setDragVal((current) => {
      return {...current, mx: e.clientX, cx: wheelVal }
    });
  }

  const onDragMove = (e:any) => {
   
    if(isDrag) {
       const dx = e.clientX - dragVal.mx;
      setWheelVal( (current: number) => {
        current = Math.min(Math.max(-(totalW), dragVal.cx + dx), 0);
        return current;
      });
      setRatioVal( (current:any) => {
        current = Number(((-wheelVal / (totalW)) * 100).toFixed(2));
        return current;
      });
    }
  }

  const onDragEnd = () => {
    setIsDrag(false);
  }

  useEffect(() => {
    
    setLayout();
    resetFtn();
    window.addEventListener('resize', () => {
      setLayout();
      resetFtn();
    });

  },[])


  let pointerEventVal = false;
  if(ratioVal > 7.7) {
    pointerEventVal = true;
  }

  const value:any = useMemo(() => ({ 
    contentInfo,
    menuOnOff,
    ratioVal,
    wheelOn,
    playAnimation,
    movePos,
    menuFtn,
  }), [contentInfo, menuOnOff, ratioVal, wheelOn, playAnimation, movePos, menuFtn]);

  return (

    <div 
      className={`${styles.container}`}
      onWheel={(menuOnOff || isPlayer) ? undefined : setWheel}
      onMouseDown={onDragStart}
      onMouseMove={(!isDrag || menuOnOff || isPlayer) ? undefined : onDragMove}
      onMouseUp={onDragEnd}
      onMouseLeave={onDragEnd}
    >
      <PcDefaultVal.Provider value={value}>
      <Menu_PC />
      <Nav_PC />
      <div 
        className={styles.wheelBody} 
        style={{
          transform: `translate3d(${isWheel? undefined : wheelVal}px, 0px, 0px)`, 
          width: `${totalW}px`, 
          pointerEvents: `${pointerEventVal ?'initial' : 'none'}`,
          marginLeft: `${contentInfo[1].contentWidth}px`,
        }}
      >
        <Content02 />
        <Content03 />
        <Content04 />
        <Content05 />
        <Content06 />
      </div>
      <Content01 isPlayer={isPlayer} setIsPlayer={setIsPlayer} />
      </PcDefaultVal.Provider>
    </div>
  );
}

export default MainPC;
