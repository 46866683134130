import React, { createContext } from 'react';

export const UserDefaultVal:any = createContext({
  loadingVal: false,
  setLoadingVal: () => {},
  soundVal: false,
  setSoundVal: () => {},
  soundOnOff: () => {},
  clientData: [],
  deviceVal: '',
  t: '',
  i18n: '',
});

export const PcDefaultVal:any = createContext({
  contentInfo: [],
  menuOnOff: false,
  ratioVal: 0,
  wheelOn: 0,
  playAnimation: () => {},
  movePos: () => {},
  menuFtn: () => {},
});

export const MobileDefaultVal:any = createContext({
  menuOnOff: false,
  ratioVal: 0,
  menuFtn: () => {},
  scrollMove: () => {},
  playAniClass: () => {},
});