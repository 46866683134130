import React, { useRef, useContext, useCallback }  from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import styles from '../styles/Content05_M.module.css';
import Swiper_item_M from './Swiper_item_M';
import { UserDefaultVal, MobileDefaultVal } from '../../UserContextVal';

  /** SwiperCore 사용 */
  SwiperCore.use([Pagination, Autoplay]);

const Content05_M = React.forwardRef(({}, ref:any) => {
  const { t, clientData } = useContext(UserDefaultVal);
  const { playAniClass } = useContext(MobileDefaultVal);

  const ctnElm01:any = useRef();


  let referenceData:any = [...clientData];
  let tmp:any = [];

  const divsion = () => {
    let len = referenceData.length;
    let cnt = 0;

    cnt = Math.floor(len / 3) + (Math.floor(len % 3) > 0 ? 1: 0);  

    for(let i = 0; i < cnt; i++) {
      tmp.push(referenceData.splice(0, 3));
    }
    return tmp;
  }

  divsion();


  // const setSwiperElm:any = (content:any, index:any) => {

  //   const swiperElm :any = [];  
    
  //   for(let i = 0; i < tmp.length; i++) {
  //     swiperElm.push(
  //       <SwiperSlide key={i} virtualIndex={i} className='cardElm_m'> 
  //         <Swiper_item_M key={i} index={i} info={tmp}/>
  //       </SwiperSlide>
  //     );  
  //   }
  //   return swiperElm;
  // }  

  const setSwiperElm = useCallback(() => {
    const swiperElm :any = [];  
      
    for(let i = 0; i < tmp.length; i++) {
      swiperElm.push(
        <SwiperSlide key={i} virtualIndex={i} className='cardElm_m'> 
          <Swiper_item_M key={i} index={i} info={tmp}/>
        </SwiperSlide>
      );  
    }
    return swiperElm;
  },[clientData])

  return (
  <div 
    className={styles.container} 
    ref={ref} 
  >
    <div 
      className={`${styles.textWrap} ${playAniClass(ctnElm01) ? styles.active01 : ''}`}
      ref={ctnElm01} 
    >
      <h2 className={styles.title}>Reference</h2>
      <p className={styles.subText}>{t('cont05_desc_01')}</p>
    </div>
    <Swiper
      className='cardSwiper_m'
      direction={'horizontal'}
      pagination={true}
      slidesPerView={1.5}
    >
      {setSwiperElm()}
    </Swiper>
  </div>

  );
}) 

export default Content05_M;