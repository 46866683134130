import React, { useState, useContext, useEffect, useCallback }  from 'react';
import styles from '../styles/Swiper_item.module.css';
import { UserDefaultVal } from '../../UserContextVal';

function Swiper_item({index, info}:any) {
  const { i18n } = useContext(UserDefaultVal);
  const [itemM, setItemM] = useState(0);
  const [itemH, setItemH] = useState(0);

  useEffect(() => {

    const setLayout = () => {
      setItemM(current => current = window.innerHeight * 0.03333);

      if (window.innerWidth >= 1440 && window.innerWidth <= 1920) {
        setItemH(current => current = (window.innerHeight / 3 - 36) * 0.646);
      } else if (window.innerWidth < 1440 || window.innerWidth > 1920) {
        setItemH(current => current = (window.innerHeight / 4 - 36) * 0.646);
      }
    }
    setLayout();

    window.addEventListener('resize', setLayout);

    return () => {
      window.removeEventListener('resize', setLayout);
    }
    
  }, [])


  return (
  <div className={styles.container}>
    {info[index].map((content:any, index:any) => (
      <div 
        key={index}
        className='items'
        style={{width: `${itemH}px`, marginLeft: `${itemM}px`}}
      >
        <div 
          className={`item item${index}`}
          style={{background: content.background}}
        >
          <div className='swi_logoWrap'>
            <img src={require(`../../assets/reference_logo/${content.logo}`)} alt="logo" />
          </div>
          <div className='swi_innerText'>
            {i18n.language === 'ko' ?
              <p>{content.description_kr}</p>
              :
              <p>{content.description_en}</p>
            }
          </div>
        </div>
      </div>
    ))}
  </div>
  );
}

export default Swiper_item;
