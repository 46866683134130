import React, { useState, useEffect, useContext, useCallback }  from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import styles from '../styles/Content05.module.css';
import Swiper_item from './Swiper_item';
import { UserDefaultVal,  PcDefaultVal } from '../../UserContextVal';

  /** SwiperCore 사용 */
  SwiperCore.use([Pagination, Autoplay]);

function Contnet05() {
  const { clientData, t, i18n} = useContext(UserDefaultVal);
  const { contentInfo, playAnimation, ratioVal } = useContext(PcDefaultVal);
  let referenceData:any = [...clientData];
  let tmp:any = [];

  const divsion = () => {
    let len = referenceData.length;
    let cnt = 0;

    cnt = Math.floor(len / 4) + (Math.floor(len % 4) > 0 ? 1: 0);  

    for(let i = 0; i < cnt; i++) {
      tmp.push(referenceData.splice(0, 4));
    }
    return tmp;
  }

  divsion();

  const setSwiperElm = useCallback(() => {
    const itemMargin = window.innerHeight * 0.03333;
    const swiperElm: any = [];

    for (let i = 0; i < tmp.length; i++) {
      swiperElm.push(
        <SwiperSlide key={i} virtualIndex={i} className='cardElm' style={{ paddingBottom: `${itemMargin}px` }}>
          <Swiper_item key={i} index={i} info={tmp} />
        </SwiperSlide>
      );
    }
    return swiperElm;
  }, [clientData])

  let rGrud;  

  if(window.innerWidth >= 1440 && window.innerWidth <= 1920) {
    rGrud = 3;
  } else if(window.innerWidth < 1440 || window.innerWidth > 1920) {
    rGrud = 4;
  }

  const values = contentInfo[5].values;
  let contentAni01_h1 = 0;
  let contentAni01_t = 0;
  
  if(ratioVal >= 65 && ratioVal <= 96.33) {
    contentAni01_h1 = playAnimation(values.contentAni01_h1);
    contentAni01_t = playAnimation(values.contentAni01_t);
  }


  return (
    <div className={styles.container} style={{width: `${contentInfo[5].contentWidth}px`}}>
      <div className={styles.textWrap}>
        <h2 
          className={styles.title} 
          style={{ opacity: `${contentAni01_h1}` }}>
            Reference
        </h2>
        <p 
          className={styles.subText}
          style={{ opacity: `${contentAni01_t}` }}
        >
          {i18n.language === 'ko' ?
          <>
          <span className={styles.subText_kr}>
            {t('cont05_desc_01')}
          </span>
          <span className={styles.subText_kr}>
            {t('cont05_desc_02')}
          </span>
          </>
          :
          <>
          <span className={styles.subText_en}>
            {t('cont05_desc_01')}
            {t('cont05_desc_02')}
          </span>
          </>
          }
        </p>
      </div>
    <Swiper
      className='cardSwiper'
      direction={'vertical'}
      slidesPerView={rGrud}
      grid={{
        "rows": 4
      }}
    >
      {setSwiperElm()}
    </Swiper>
    </div>
  );
}

export default Contnet05;
