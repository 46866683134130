import React, { useState } from 'react';
import YouTube from 'react-youtube';
import styles from '../styles/VideoPlayer.module.css';


const VideoPlayer = ({isPlayer, playerOnOff}:any) => {
    const [player, setPlayer]:any = useState();

    const isYouTube = () => {
        player.pauseVideo();
    }
    

  return (
    <div 
    className={styles.container}
    style={{ 
      opacity: isPlayer ? 1 : 0,
      pointerEvents: isPlayer ? 'initial' : 'none',
    }}
  >
    <div className={styles.frame}>
      <YouTube 
        videoId="erpw2P-fWPE" 
        opts={{
          playerVars: {
            autoplay: 0,
            rel: 0,
          },
        }}
        onReady={(event) => 
            setPlayer((current:any) => {
                current = event.target;
                return current;
            })} 
      />
    </div>
    <div 
      className={styles.closeArea}
      onClick={() => {
        playerOnOff() 
        isYouTube()
      }}
    >
    </div>
  </div>

  );

}

export default VideoPlayer;
