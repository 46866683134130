import React from 'react';
import styles from '../styles/IE_Response.module.css';
import browserIco from '../../assets/icons/browser.svg';
import chromeIco from '../../assets/icons/chrome.svg';
import edgeIco from '../../assets/icons/edge.svg';
import firefoxIco from '../../assets/icons/firefox.svg';


function IE_Response() {
  return (
    <div className={styles.container}>
      <div className={styles.contentWrap}>
        <div className={styles.descWrap}>
          <img src={browserIco} alt="아이콘" />
          <h3>다른 브라우저를 사용해주세요</h3>
          <p>SUPERNOVA Web은 Internet Explorer 브라우저를 지원하지 않습니다.</p>
          <p>아래 다른 브라우저를 설치 후 이용해 주시길 바랍니다.</p>
        </div>
        <div className={styles.iconWrap}>
           <div className={styles.icon01}>
             <a href="https://www.google.com/chrome/" target="_blank">
               <img src={chromeIco} alt="크롬 아이콘" />
               <p>Chrome</p>
             </a>
           </div>
           <div className={styles.icon02}>
             <a href="https://www.microsoft.com/edge/" target="_blank">
               <img src={edgeIco} alt="엣지 아이콘" />
               <p>Edge</p>
             </a>
           </div>
           <div className={styles.icon03}>
             <a href="https://www.mozilla.org/firefox/new/" target="_blank">
               <img src={firefoxIco} alt="파이어폭스 아이콘" />
               <p>Firefox</p>
             </a>
           </div>
        </div>
      </div>
    </div>
  );
}

export default IE_Response;
