import React, { useState, useRef, useEffect, useContext }  from 'react';
import styles from '../styles/Content02_M.module.css';
import VideoA01 from '../../assets/video/after.webm';
import VideoA02 from '../../assets/video/after.mp4';
import VideoB01 from '../../assets/video/before.webm';
import VideoB02 from '../../assets/video/before.mp4';
import HandlerSvg from '../../assets/icons/handler.svg';
import Video from '../../common/component/Video';
import { UserDefaultVal, MobileDefaultVal } from '../../UserContextVal';

const Content02_M = React.forwardRef(({}, ref:any) => {
  const { t } = useContext(UserDefaultVal);
  const { playAniClass } = useContext(MobileDefaultVal);

  const vidARef:any = useRef(null);
  const vidBRef:any = useRef(null);
  const ctnElm01:any = useRef(null);
  const ctnElm02:any = useRef(null);


const [assetsW, setAssetsW] = useState(0);

  useEffect(() => {

    setAssetsW((current) => {
      current = window.innerWidth * 0.933333;
      return current;
    })

    // if(window.innerWidth >= 320 && window.innerWidth <= 820) {
    //   setAssetsW((current) => {
    //     current = window.innerWidth * 0.933333;
    //     return current;
    //   })
    // } 
    //  else if (window.innerWidth < 320) {
    //   setAssetsW((current) => {
    //     current = 298.6;
    //     return current;
    //   })
    // } else if (window.innerWidth > 820) {
    //   setAssetsW((current) => {
    //     current = 765.3;
    //     return current;
    //   })
    // }
    window.addEventListener('resize', () => {
      setAssetsW((current) => {
        current = window.innerWidth * 0.933333;
        return current;
      })
    });

  }, [])

  const [isTouch, setIsTouch] = useState(false);
  const [touchVal, setTouchVal] = useState({mx: 0, cx: 0});
  const [touchEndVal, setTouchEndVal] = useState(0);

  const onLoad = () => {
    setTouchEndVal((currnet) => {
      currnet = assetsW / 2;
      return currnet;
    });
  }
  
  const onTouchStart = (e:any) => {
    // e.preventDefault();
    setIsTouch(true);
    setTouchVal((current) => {
      return {...current, mx: e.touches[0].clientX, cx: touchEndVal}
    });
  }
  const onTouchMove = (e:any) => {

    if (isTouch) {
      const dx = e.touches[0].clientX - touchVal.mx;

      setTouchEndVal((currnet) => {
        currnet = Math.min(Math.max(0, touchVal.cx + dx), assetsW);
        return currnet;
      });
    };
  }

  const onTouchEnd = () => {
    setIsTouch(false);
  }

  useEffect(() => {
    if(vidARef.current.currentTime - vidBRef.current.currentTime > 0.05) {
      vidARef.current.currentTime = vidBRef.current.currentTime;
      vidBRef.current.currentTime = vidARef.current.currentTime;
    } 
  }, [isTouch, touchEndVal])

  return (
    <div className={styles.container}>
        <div className={`${styles.contentWrap} ${playAniClass(ctnElm01) ? styles.active01 : ''}`}>
          <div 
            className={`${styles.titleBox}`} 
            ref={ctnElm01}
          >
            <h2 className={styles.ani01_h1}>{t('cont02_title_01_01')}</h2>
            <h2 className={styles.ani02_h1}>{t('cont02_title_01_02')}</h2>
          </div>
          <div className={styles.subText}>
            <p>{t('cont02_desc_01')}</p>
          </div>
        </div>
        <div 
          className={`${styles.assetsWrap} ${playAniClass(ctnElm02) ? styles.active02 : ''}`} 
          onTouchMove={isTouch ? onTouchMove : undefined}
          onLoad={onLoad}
          id='mouseElm'
          ref={ctnElm02}
        >
          <div 
            className={styles.handlerLine}
            style={{left: `${touchEndVal}px`}}
          >
            <span 
              className={styles.handlerIco}
              onTouchStart={onTouchStart}
              onTouchEnd={onTouchEnd}
            >
              <img src={HandlerSvg} alt="ico" />
            </span>
          </div>
            <div 
              className={styles.afterWrap} 
              style={{width: `${(touchEndVal / assetsW) * 100}%`}}
            >
              <Video 
                VideoWebm={VideoA01} 
                VideoMp4={VideoA02} 
                ref={vidARef}
                HiddenClass={'cnt_02_m'}
              />
            </div>
            <div
              className={styles.beforeVideo}
              style={{width: assetsW}}
            >
              <Video 
                VideoWebm={VideoB01} 
                VideoMp4={VideoB02} 
                ref={vidBRef}
                HiddenClass={'cnt_02_m'}
              />
            </div>
        </div>
    </div>
  );
}) 

export default Content02_M;
