import React, { useEffect, useState, useContext }  from 'react';
import styles from '../styles/Menu_M.module.css';
import { UserDefaultVal, MobileDefaultVal } from '../../UserContextVal';


function Menu_M() {
  const { i18n } = useContext(UserDefaultVal);
  const { menuOnOff, menuFtn, scrollMove } = useContext(MobileDefaultVal);

  return (
    <div className={styles.container} style={{transform: `translate3d(-${menuOnOff ? 0 : 100}%, 0px, 0px)`}}>
      <div className={`${styles.close} ${menuOnOff ? styles.active : ''}`} onClick={menuFtn}>
        <span className={styles.c_01}></span>
        <span className={styles.c_02}></span>
      </div>
      <nav className={styles.menuWarp}>
        <ul>
          <li 
            className={styles.m_01}
            onClick={() => scrollMove('top')}
          >
            Main
          </li>
          <li 
            className={styles.m_02}
            onClick={() => scrollMove('about')}
          >
            About
          </li>
          <li 
            className={styles.m_03}
            onClick={() => scrollMove('reference')}
          >
            Reference
          </li>
          <li 
            className={styles.m_04}
            onClick={() => scrollMove('exprience')}
          >
            Exprience
          </li>
        </ul>
      </nav>
      <div className={styles.language}>
            <div
              className={styles.kr} 
              style={{
                opacity: `${ i18n.language === 'ko' ? '1' : '0.6'}`, 
                fontWeight: `${ i18n.language === 'ko' ? 600 : 300}`
              }}
              onClick={() => {
                i18n.changeLanguage('ko'); 
                menuFtn();
              }}
            >
                KR
            </div>
            <span></span>
            <div 
              className={styles.en} 
              style={{
                opacity: `${ i18n.language === 'ko' ? '0.6' : '1'}`, 
                fontWeight: `${ i18n.language === 'ko' ? 300 : 600}`
              }}
              onClick={() => {
                i18n.changeLanguage('en');
                menuFtn();
              }}
            >
              EN
            </div>
          </div>
    </div>
  );
}

export default Menu_M;
