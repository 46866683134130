import React, {useContext} from 'react';
import styles from '../styles/Guide.module.css';
import { UserDefaultVal } from '../../UserContextVal';

function Guide({ guideVal }:any) {
  const { loadingVal } = useContext(UserDefaultVal);

  return (
    <div 
      className={`${styles.container }`} 
      style={{opacity: loadingVal ? 1 : 0}}
    >
      <div 
        className={`${styles.guideWrap} ${guideVal ? styles.inDisplay : ''}`} 
        style={{
          opacity: loadingVal && guideVal === false ? 1 : 0, 
          pointerEvents: !loadingVal ||  guideVal ? 'none' : 'initial'
        }}
      >
        <div 
          className={styles.gcw}
          style={{opacity: loadingVal ? 1 : 0}}
        >
          <p>마우스를 스크롤하면 화면이 좌우로 이동합니다</p>
          <div className={styles.mouse}>
            <span className={styles.mouseB}>
              <span className={styles.mouseW}></span>
            </span>
            <span className={styles.down}>
              <span className={styles.d01}></span>
              <span className={styles.d02}></span>
              <span className={styles.d03}></span>
              <span className={styles.d04}></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Guide;
