import React from 'react';
import styles from '../styles/HttpError.module.css';
import ErrorIco from '../../assets/icons/http_error.svg';
import { Link } from "react-router-dom";


function HttpError() {
  return (
    <div className={styles.container}>
      <div className={styles.contentWrap}>
        <div className={styles.descWrap}>
          <img src={ErrorIco} alt="아이콘" />
          <h3>페이지를 찾을 수 없습니다</h3>
          <p>페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.</p>
        </div>
        <div className={styles.homeBtn}>
          <Link to="/">SUPERNOVA 홈으로 이동</Link>
        </div>
      </div>
    </div>
  );
}

export default HttpError;
