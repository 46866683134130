import React, {useContext}  from 'react';
import styles from '../styles/Content04.module.css';
import Video from '../../common/component/Video';
import Video01 from '../../assets/video/before-after.webm';
import Video02 from '../../assets/video/before-after.mp4';
import { UserDefaultVal,  PcDefaultVal } from '../../UserContextVal';

function Contnet04() {
  const {t} = useContext(UserDefaultVal);
  const { contentInfo, playAnimation, ratioVal} = useContext(PcDefaultVal);
  const values = contentInfo[4].values;
  let aniVal01_h1 = 0;


  if (ratioVal >= 49.5 && ratioVal <= 80.5) {
    aniVal01_h1 = playAnimation(values.contentAni01_t);
  }

  return (
    <div className={styles.container} style={{width: `${contentInfo[4].contentWidth}px`}}>
      <div className={styles.assetsWrap}>
        <Video VideoWebm={Video01} VideoMp4={Video02} />
        <p 
          className={styles.desc}
          style={{opacity: `${aniVal01_h1}`}}
        >
          {t('cont04_desc_01')}
        </p>
      </div>
    </div>
  );
}

export default Contnet04;
