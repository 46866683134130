import React, { useCallback, useState, useContext }  from 'react';
import styles from '../styles/Menu_PC.module.css';
import { PcDefaultVal } from '../../UserContextVal';

function Menu_PC() {
  const [isClose, setIsClose] = useState(false);
  const { menuOnOff ,menuFtn, movePos} = useContext(PcDefaultVal);

  const closeActive = useCallback(() => {
    if (menuOnOff) {
      setTimeout(() => {setIsClose(true)}, 450);
    } else {
      setTimeout(() => {setIsClose(false)}, 450);
    }
  }, [menuOnOff])
  closeActive();

  return (
    <div className={styles.container} style={{transform: `translate3d(-${menuOnOff ? 0 : 100}%, 0px, 0px)`}}>
      <div className={`${styles.close} ${isClose ? styles.active : ''}`} onClick={menuFtn}>
        <span className={styles.c_01}></span>
        <span className={styles.c_02}></span>
      </div>
      <nav className={styles.menuWarp}>
        <ul>
          <li 
            className={styles.m_01}
            onClick={() => movePos('main')}
          >
            Main
          </li>
          <li 
            className={styles.m_02}
            onClick={() => movePos('about')}
          >
            About
          </li>
          <li 
            className={styles.m_03}
            onClick={() => movePos('reference')}
          >
            Reference
          </li>
          <li 
            className={styles.m_04}
            onClick={() => movePos('exprience')}
          >
            Exprience
          </li>
        </ul>
      </nav>
      <div className={styles.isPointer} style={{display: menuOnOff ? 'block' : 'none'}}></div>
    </div>
  );
}

export default Menu_PC;
