import React, { useState, useRef, useEffect, useContext }  from 'react';
import styles from '../styles/Content01.module.css';
import Loading from '../../common/component/Loading';
import Guide from './Guide';
import VideoPlayer from '../../common/component/VideoPlayer';
import { UserDefaultVal,  PcDefaultVal } from '../../UserContextVal';


function Content01({isPlayer, setIsPlayer}:any) {
  const [guideVal, setGuideVal] = useState(false);
  const { loadingVal, t } = useContext(UserDefaultVal);
  const { contentInfo, ratioVal, wheelOn } = useContext(PcDefaultVal);
  // const [isPlayer, setIsPlayer] = useState(false);

  const playerOnOff = () => {
    setIsPlayer((current:any) => current = !isPlayer);
  }

  useEffect(() => {

    if(ratioVal >= 0 && ratioVal <= 15.6) if(wheelOn !== 0) setGuideVal(true);

  }, [ratioVal, wheelOn ])


  return (
    <>
    <Loading isPlayer={isPlayer} />
    <Guide guideVal={guideVal} />
    <VideoPlayer isPlayer={isPlayer} playerOnOff={playerOnOff} />
    <div className={styles.container} style={{width: `${contentInfo[1].contentWidth}px`}}>
      <div className={`${styles.contentWrap} ${loadingVal ? styles.active : ''}`}>
        <div className={`${styles.titleBox}`} 
        >
          <h2 className={styles.cnt1_ani_t01}>The world</h2>
          <h2 className={styles.cnt1_ani_t02}>We meet again</h2>
          <h2 className={styles.cnt1_ani_t03}>by SUPERNOVA</h2>
        </div>
        <div 
          className={styles.button} 
          onClick={()=> playerOnOff()}
        >
          <p className={styles.cnt1_ani_t04}>Show Video</p>
          <div className={styles.buttonIco}>
              <div className={styles.bIcoWrap01}>
                <span className={styles.bIco01}></span>
                <span className={styles.bIco02}></span>
              </div>
          </div>
        </div>
        <p className={styles.desc}>{t('cont04_desc_01')}</p>
      </div>
    </div>
    </>
  );
}

export default Content01;
