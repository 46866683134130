import React from 'react';
import styles from '../styles/Video.module.css';

const Video = React.forwardRef(({VideoWebm, VideoMp4, HiddenClass, Poster}:any, ref:any) => {

  return (

    <video
      className={`${HiddenClass === 'cnt_02_m' ? styles.cnt_02_m : styles.container }`}
      ref={ref} 
      autoPlay 
      muted 
      loop
      playsInline
      preload="none"
      poster={Poster}
    >
      <source src={VideoWebm} type="video/webm" />
      <source src={VideoMp4} type="video/mp4" />
    </video>

  )

});

export default Video;
