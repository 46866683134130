import React, { useState, useRef, useContext }  from 'react';
import styles from '../styles/Content06_M.module.css';
import Webimage from '../../assets/img/exprience_01_m.png';
import Appimage from '../../assets/img/exprience_02_m.png';
import GoogleIcon from '../../assets/icons/android.svg';
import IosIcon from '../../assets/icons/ios.svg';
import { UserDefaultVal, MobileDefaultVal } from '../../UserContextVal';


const Content06_M = React.forwardRef(({}, ref:any) => {
  const { t } = useContext(UserDefaultVal);
  const { playAniClass, scrollMove } = useContext(MobileDefaultVal);

  const ctnElm01_sc:any = useRef();
  const ctnElm01_t:any = useRef();
  const ctnElm02_sc:any = useRef();
  const ctnElm02_t:any = useRef();
  const ctnElm03_ico:any = useRef();

  return (
    <div 
      className={styles.container} 
      ref={ref} 
    >
      <div className={styles.webContainer}>
        <div className={styles.imgWrap}>
          <img
            className={`${playAniClass(ctnElm01_sc) ? styles.active01_sc : ''}`}
            src={Webimage} 
            alt="웹사이트 예시 이미지"
            ref={ctnElm01_sc} 
          />
        </div>
        <div 
          className={`${styles.textWrap} ${playAniClass(ctnElm01_t) ? styles.active01_t : ''}`}
          ref={ctnElm01_t}
        >
          <div className={styles.title}>
            <h2>{t('cont06_title_01')}</h2>
          </div>
          <div className={styles.desc}>
            <p>{t('cont06_desc_01_01')}</p>
            <p>
              {t('cont06_desc_01_02')}&nbsp;
              <a href="mailto:supernova-prd@sktelecom.com">{t('cont06_desc_01_03')}</a>
              {t('cont06_desc_01_04')}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.appContainer}>
        <div className={styles.imgWrap}>
          <img
            className={`${playAniClass(ctnElm02_sc) ? styles.active02_sc : ''}`}
            src={Appimage} 
            alt="앱 예시 이미지"
            ref={ctnElm02_sc} 
          />
        </div>
        <div 
          className={`${styles.textWrap} ${playAniClass(ctnElm02_t) ? styles.active02_t : ''}`}
          ref={ctnElm02_t}
        >
          <div className={styles.title}>
            <h2>{t('cont06_title_02')}</h2>
          </div>
          <div className={styles.desc}>
            <p>{t('cont06_desc_02')}</p>
          </div>
          <div className={styles.btnWrap}>
            <button className={styles.androidBtn}>
              <img src={GoogleIcon} alt="구글 스토어 아이콘" />
              <p>Android</p>
            </button>
            <button className={styles.iosBtn}>
              <img src={IosIcon} alt="애플 스토어 아이콘" />
              <p>IOS</p>
            </button>
          </div>
        </div>
      </div>
      <div 
        className={`${styles.goMainTrigger} ${playAniClass(ctnElm03_ico) ? styles.active03_ico : ''}`} 
        onClick={() => scrollMove('top')}
        ref={ctnElm03_ico}
      >
        <div className={styles.arrowIco01}>
          <span className={styles.arrow01}></span>
          <span className={styles.arrow02}></span>
        </div>
        <p>GO Main</p>
      </div>
    </div>

  );
}) 

export default Content06_M;