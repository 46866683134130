import React, { useState, useRef, useEffect, useMemo, useCallback }  from 'react';
import './common/styles/reset.css';
import './common/styles/fonts.css';
import './common/styles/globalStyles.css';
import './common/styles/globalAnimation.css';

import HttpError from './common/component/HttpError';
import IE_Response from './common/component/IE_Response';
import Main_PC from './pc_web/component/Main_PC';
import Main_M from './moblie_web/component/Main_M';
import Bgm from './assets/bgm/bgm.mp3';
import './i18n/i18n'
import { useTranslation } from 'react-i18next'
import { UserDefaultVal } from './UserContextVal'


function App() {
  const { t, i18n } = useTranslation()
  const [loadingVal, setLoadingVal] = useState(false);
  const [deviceVal, setDeviceVal] = useState('');
  const [soundVal, setSoundVal] = useState(false);
  const bgmRef:any = useRef(null);
  const [clientData, setClientData]:any = useState([]);
  const [httpError, setHttpError]:any = useState(false);

  useEffect(() => {
    const fetchError = () => {
      fetch('https://d57n13ggqo58n.cloudfront.net/').then(response => {
        if (response.ok) {
          console.log('HTTP-status', response.status);
          setHttpError((current:boolean) => current = false);
        } else {
          console.log('HTTP-Error:', response.status);
          setHttpError((current:boolean) => current = true);
        }
        
      })
    }
    fetchError();
  }, [])


  const soundOnOff = useCallback(() => {
    setSoundVal( current => current = !soundVal);
  },[soundVal]);


  const value:any = useMemo(() => ({
    loadingVal,
    setLoadingVal,
    soundVal,
    setSoundVal,
    soundOnOff,
    clientData,
    deviceVal,
    t,
    i18n,
  }), [loadingVal, setLoadingVal, soundVal, setSoundVal, clientData, t, i18n]);

  useEffect(() => {
    const bgmPlayer = () => {
      if (soundVal === true) {
        bgmRef.current.play();
      } else if(soundVal === false) {
        bgmRef.current.pause();
        bgmRef.current.currentTime = 0;
      }
    }
    bgmPlayer();

    window.addEventListener('load',bgmPlayer);
    return () => {
      window.removeEventListener('load',bgmPlayer);
    }
  }, [soundVal])


  const setLayout = () => {
    const user = navigator.userAgent;
    const agt = navigator.userAgent.toLowerCase();

    if( user.indexOf("iPhone") > -1
        || user.indexOf("Android") > -1
        || user.indexOf("iPad") > -1
        || user.indexOf("iPod") > -1
    ) {
      setDeviceVal(current => current = 'Moboile');
    } else if( agt.indexOf("msie") !== -1
               || agt.indexOf("trident") !== -1
            ) {
              setDeviceVal(current => current = 'InternetExplorer');
            } else {
              setDeviceVal(current => current = 'PC');
            }
  }

  const getReferData = async () => {
    const json = await (
      await fetch('/reference.json')
    ).json();
    for (let i = 0; i < json.length; i++) {
      const randomVal = Math.floor(Math.random() * 3);
      let backgroundVal;
      if (randomVal === 0) {
        backgroundVal = '#2E2B26';
      } else if (randomVal === 1) {
        backgroundVal = '#E88C45';
      } else {
        backgroundVal = '#C64A4A';
      }
      json[i].background = backgroundVal;
      if(json[i].description_en.length >= 97) {
        json[i].description_en = json[i].description_en.substr(0,97) + "...";
      }
      if(json[i].description_kr.length >= 73) {
        json[i].description_kr = json[i].description_kr.substr(0,73) + "...";
      }
    }
    setClientData(json);
  }

  useEffect(() => {
    getReferData();
    setLayout();
  }, [])


  const bodyElm  = document.querySelector('body') as HTMLElement;

  useEffect(() => {
    if(!loadingVal) {
      bodyElm.style.overflow = 'hidden';
      bodyElm.style.position = 'fixed';
      bodyElm.style.top = '0';
      bodyElm.style.left= '0';
    } else {
      bodyElm.style.removeProperty('overflow');
      bodyElm.style.removeProperty('position');
      bodyElm.style.removeProperty('top');
      bodyElm.style.removeProperty('left');
    }

  }, [loadingVal])



  if (httpError) {
    return (
      <HttpError />
    );

  } else if (deviceVal === 'InternetExplorer') {
    return (
      <IE_Response />
    );
  } else {
    return (
      <div className='App'>
        <audio ref={bgmRef} loop>
          <source src={Bgm} type="audio/mp3" />
        </audio>
        <UserDefaultVal.Provider value={value}>
          {(deviceVal === 'PC') ?
            <Main_PC />
            :
            <Main_M />
          }
        </UserDefaultVal.Provider>
      </div>
    );
  }


}

export default App;
