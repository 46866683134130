import React, { useRef, useContext }  from 'react';
import styles from '../styles/Content04_M.module.css';
import Video01 from '../../assets/video/before-after-mobile.webm';
import Video02 from '../../assets/video/before-after-mobile.mp4';
import Video from '../../common/component/Video';
import { UserDefaultVal, MobileDefaultVal } from '../../UserContextVal';

const Content04_M = React.forwardRef(({}, ref:any) => {
  const { t } = useContext(UserDefaultVal);
  const { playAniClass } = useContext(MobileDefaultVal);

  const ctnElm01:any = useRef(null);

  return (
    <div 
    className={styles.container}
    ref={ref}
  >
    <div className={styles.assetsWrap}>
      <Video VideoWebm={Video01} VideoMp4={Video02} />
      <p 
        className={`${styles.desc} ${playAniClass(ctnElm01) ? styles.active01 : ''}`}
        ref={ctnElm01}
      >
        {t('cont04_desc_01')}
      </p>
    </div>
  </div>

  );
}) 

export default Content04_M;