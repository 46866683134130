import React, {useContext} from 'react';
import styles from '../styles/Content03.module.css';
import Image01 from '../../assets/img/content03-01.png';
import Image02 from '../../assets/img/content03-02.png';
import Image03 from '../../assets/img/content03-03.png';
import Image04 from '../../assets/img/content03-04.png';
import { UserDefaultVal,  PcDefaultVal } from '../../UserContextVal';


function Contnet03() {
  const { t, i18n } = useContext(UserDefaultVal);
  const { contentInfo, playAnimation, ratioVal } = useContext(PcDefaultVal);
  const values = contentInfo[3].values;
  const cnt03 = {
    aniVal01_h1: 0,
    aniVal01_2_h1: 0,
    aniVal01_opc: 0,
    aniVal01_m: 0,
    aniVal02_opc: 0,
    aniVal02_m: 0,
    aniVal02_h1: 0,
    aniVal02_2_h1: 0,
    aniVal03_opc: 0,
    aniVal03_m: 0,
    aniVal04_opc: 0,
    aniVal04_m: 0,
  }

  if(ratioVal >= 15.6 && ratioVal <= 65.1) {

    cnt03.aniVal01_h1 = playAnimation(values.contentAni01_h1);
    cnt03.aniVal01_2_h1 = playAnimation(values.contentAni01_2_h1);
    cnt03.aniVal01_opc = playAnimation(values.contentAni01_opc);
    cnt03.aniVal01_m = playAnimation(values.contentAni01_m);
    cnt03.aniVal02_opc = playAnimation(values.contentAni02_opc);
    cnt03.aniVal02_m = playAnimation(values.contentAni02_m);
    cnt03.aniVal02_h1 = playAnimation(values.contentAni02_h1);
    cnt03.aniVal02_2_h1 = playAnimation(values.contentAni02_2_h1);
    cnt03.aniVal03_opc = playAnimation(values.contentAni03_opc);
    cnt03.aniVal03_m = playAnimation(values.contentAni03_m);
    cnt03.aniVal04_opc = playAnimation(values.contentAni04_opc);
    cnt03.aniVal04_m = playAnimation(values.contentAni04_m);
  }

  return (
    <div className={styles.container} style={{width: `${contentInfo[3].contentWidth}px`}}>
      <div className={styles.title01}>
        <div>
          {i18n.language === 'ko' ?
            <>
              <div style={{ opacity: `${cnt03.aniVal01_h1}` }}>
                {t('cont03_title_01_01')}
                <span className={styles.pointText}>
                  {t('cont03_title_01_02')}
                </span>
                {t('cont03_title_01_03')}
              </div>
              <div style={{ opacity: `${cnt03.aniVal01_2_h1}` }}>
                {t('cont03_title_01_04')}
              </div>
            </>
          :
            <>
              <div style={{ opacity: `${cnt03.aniVal01_h1}` }}>
                {t('cont03_title_01_01')}
                {t('cont03_title_01_02')}
              </div>
              <div style={{ opacity: `${cnt03.aniVal01_2_h1}` }}>
                {t('cont03_title_01_03')}
                {t('cont03_title_01_04')}
                <span className={styles.pointText}>
                  {t('cont03_title_01_05')}
                </span>
                {t('cont03_title_01_06')}
              </div>
            </>
          }
        </div>
      </div>
      {/* cnt01 */}
      <div 
        className={styles.content01}
        style={{ opacity: `${cnt03.aniVal01_opc}`, transform: `translate3d(0, ${8 * cnt03.aniVal01_m}%, 0)`}}
      >
        <div className={styles.imgWrap}>
          <div className={styles.hover}>
            <p>{t('cont03_hover_01_01')}</p>
            <p>{t('cont03_hover_01_02')}</p>
            <p>{t('cont03_hover_01_03')}</p>
            <p>{t('cont03_hover_01_04')}</p>
          </div>
          <div className={styles.imgTrans}>
              <img src={Image01} alt="이미지"/>
          </div>
        </div>
        <div className={styles.textWrap}>
          <h3>
            <span>AI </span>
            Remastering
          </h3>
          <p>{t('cont03_desc_01')}</p>
        </div>
      </div>
      {/* cnt02 */}
      <div 
        className={styles.content02} 
        style={{ opacity: `${cnt03.aniVal02_opc}`, transform: `translate3d(0, -${8 * cnt03.aniVal02_m}%, 0)`}}
      >
      <div className={styles.textWrap}>
          <h3>
            <span>AI </span>
            DeOldify
          </h3>
          <p>{t('cont03_desc_02')}</p>
        </div>
        <div className={styles.imgWrap}>
          <div className={styles.hover}>
            <p>{t('cont03_hover_02_01')}</p>
            <p>{t('cont03_hover_02_02')}</p>
            <p>{t('cont03_hover_02_03')}</p>
          </div>
          <div className={styles.imgTrans}>
            <img src={Image02} alt="이미지" />
          </div>
        </div>
      </div>
      {/* tit2 */}
      <div className={styles.title02}>
        {i18n.language === 'ko' ?
          <>
            <h2 style={{ opacity: `${cnt03.aniVal02_h1}` }}>
              {t('cont03_title_02_01')}
              {t('cont03_title_02_02')}
            </h2>
            <h2 style={{ opacity: `${cnt03.aniVal02_2_h1}` }}>
              {t('cont03_title_02_03')}
              {t('cont03_title_02_04')}
            </h2>
          </>
          :
          <>
            <h2 style={{ opacity: `${cnt03.aniVal02_h1}` }}>
              {t('cont03_title_02_01')}
            </h2>
            <h2 style={{ opacity: `${cnt03.aniVal02_2_h1}` }}>
              {t('cont03_title_02_02')}
            </h2>
          </>
        }
      </div>
      {/* cnt03 */}
      <div 
        className={styles.content03}
        style={{ opacity: `${cnt03.aniVal03_opc}`, transform: `translate3d(0, ${8 * cnt03.aniVal03_m}%, 0)`}}
      >
        <div className={styles.imgWrap}>
          <div className={styles.hover}>
            <p>{t('cont03_hover_03_01')}</p>
            <p>{t('cont03_hover_03_02')}</p>
            <p>{t('cont03_hover_03_03')}</p>
          </div>
          <div className={styles.imgTrans}>
            <img src={Image03} alt="이미지" />
          </div>
        </div>
        <div className={styles.textWrap}>
          <h3>
            <span>AI </span>
            Security
          </h3>
          <p>{t('cont03_desc_03')}</p>
        </div>
      </div>
      {/* cnt04 */}
      <div 
        className={styles.content04}
        style={{ opacity: `${cnt03.aniVal04_opc}`, transform: `translate3d(0, -${8 * cnt03.aniVal04_m}%, 0)`}}
      >
      <div className={styles.textWrap}>
          <h3>
            <span>AI </span>
            Factory
          </h3>
          <p>{t('cont03_desc_04')}</p>
        </div>
        <div className={styles.imgWrap}>
          <div className={styles.hover}>
            <p>{t('cont03_hover_04_01')}</p>
            <p>{t('cont03_hover_04_02')}</p>
            <p>{t('cont03_hover_04_03')}</p>
          </div>
          <div className={styles.imgTrans}>
            <img src={Image04} alt="이미지" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contnet03;
