import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
  ko: {
    translation: {
    //01

    //02   
      cont02_title_01_01: '희미하고 흐릿한 기억에서',
      cont02_title_01_02: '생생하고 선명한 경험으로',
      cont02_desc_01: '당신의 기억이 경험이 되도록 \n우리는 SUPERNOVA로 다시 만납니다',
    //03
      cont03_title_01_01: 'SUPERNOVA는 ',
      cont03_title_01_02: 'AI ',
      cont03_title_01_03: '기술을 활용해',
      cont03_title_01_04: '미디어 품질을 개선합니다',
      cont03_title_02_01: '전문 미디어부터 ',
      cont03_title_02_02: '공정 과정까지 ',
      cont03_title_02_03: '다양한',
      cont03_title_02_04: '분야에서 응용 가능합니다',

      cont03_desc_01: '오래된 방송, 영화와 같은 전문 미디어의 \n품질을 개선하여 고화질 몰입감을 선사합니다',
      cont03_desc_02: '기억하고 간직해야할 귀중한 기록물이 \n미래세대까지 전달되도록 사회적 가치에 기여합니다',
      cont03_desc_03: 'CCTV, 범죄현장의 고도화 정보 식별 보조도구로 \n우리 일상이 보다 안전하도록 도와줍니다',
      cont03_desc_04: '비싸고 복잡한 공정 과정에 \n독자적인 인공지능 기술로 효율성을 증대시킵니다',
      cont03_hover_01_01: 'FHD,4K↑ Upscaling',
      cont03_hover_01_02: 'De-nosing, De-blurring',
      cont03_hover_01_03: 'De-interlacing',
      cont03_hover_01_04: 'Content, Color Enhancement',
      cont03_hover_02_01: '고 기록물 영상,이미지,음원 복원',
      cont03_hover_02_02: '흑백→컬러 영상 변환',
      cont03_hover_02_03: 'VHS 영상 복원',
      cont03_hover_03_01: 'CCTV 화질 개선',
      cont03_hover_03_02: 'AI Montage(얼굴 복원)',
      cont03_hover_03_03: '번호판 시인성 개선',
      cont03_hover_04_01: 'Wafer pattern QA 공정',
      cont03_hover_04_02: 'Wafer 품질 측정 자동화',
      cont03_hover_04_03: '이종 장비간 변환 기술',
    //04
      cont04_desc_01: '* SUPERNOVA 적용화면입니다.',
    //05
      cont05_desc_01: 'SUPERNOVA는 여러 공공기간, 기업과의 ',
      cont05_desc_02: '지속적인 협업으로 다양한 레퍼런스를 쌓아왔습니다',
    //05
      cont06_title_01: 'SUPERNOVA를 \n직접 경험해보세요',
      cont06_title_02: 'SUPERNOVA를 \n어디서든 경험할 수 있도록',
      cont06_desc_01_01: 'SKT에서 제공하는 ID&PW만 접속이 가능합니다.',
      cont06_desc_01_02: '서비스 이용을 원하시면',
      cont06_desc_01_03: 'supernova-prd@sktelecom.com',
      cont06_desc_01_04: '으로 문의해주세요',
      cont06_desc_02: '운영 체제에 따른 서비스 이용을 준비중입니다',
    }
  },

  en: {
    translation: {
    //01

    //02   
      cont02_title_01_01: 'From a faint memory',
      cont02_title_01_02: 'to a vivid experience',
      cont02_desc_01: 'We meet again SUPERNOVA \nYour memory becomes an experience',
    //03
      cont03_title_01_01: 'SUPERNOVA enhances ',
      cont03_title_01_02: 'media ',
      cont03_title_01_03: 'quality ',
      cont03_title_01_04: 'with ',
      cont03_title_01_05: 'AI ',
      cont03_title_01_06: 'technology',

      cont03_title_02_01: 'From professional media',
      cont03_title_02_02: 'to manufacturing process',
      cont03_title_02_01_m: 'From media to',
      cont03_title_02_02_m: 'Manufacturing process',

      cont03_desc_01: 'Provides high-definition immersion by \nenhancing the quality of classic shows.',
      cont03_desc_02: 'Contributes to social values, delivering \na valuable records to future generations.',
      cont03_desc_03: 'Advanced information identification aids for video evidence to help our daily lives be safe.',
      cont03_desc_04: 'Independent AI technology increases \nthe efficiency of complex processes.',
      cont03_hover_01_01: 'FHD,4K↑ Upscaling',
      cont03_hover_01_02: 'De-nosing, De-blurring',
      cont03_hover_01_03: 'De-interlacing',
      cont03_hover_01_04: 'Content, Color Enhancement',
      cont03_hover_02_01: 'Restore old archives : \nvideo, image, music',
      cont03_hover_02_02: 'Black and white → \nColor transformation',
      cont03_hover_02_03: 'Restore VHS video',
      cont03_hover_03_01: 'CCTV video quality \nenhancement',
      cont03_hover_03_02: 'AI montage \n(face restoration)',
      cont03_hover_03_03: 'Enhancing the visibility \nof license plates',
      cont03_hover_04_01: 'Wafer pattern \nQA process',
      cont03_hover_04_02: 'Wafer quality \nmeasurement automation',
      cont03_hover_04_03: 'Transformation technology \nbetween heterogeneous \nequipment',
    //04
      cont04_desc_01: '* SUPERNOVA application screen',
    //05
      cont05_desc_01: 'SUPERNOVA accumulated various references ',
      cont05_desc_02: 'through continuous collaboration with businesses',
    //06
      cont06_title_01: 'Experience \nSUPERNOVA',
      cont06_title_02: 'Anywhere \nwith SUPERNOVA',
      cont06_desc_01_01: 'If you want to use the service? ',
      cont06_desc_01_02: 'Please contact',
      cont06_desc_01_03: 'supernova-prd@sktelecom.com',
      cont06_desc_01_04: '',
      cont06_desc_02: 'Preparing application services',
    }
  },
};
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "ko",
  });
export default i18n;