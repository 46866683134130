import React, { useContext, useState, useEffect }  from 'react';
import styles from '../styles/Content01_M.module.css';
import Loading from '../../common/component/Loading';
import VideoPlayer from '../../common/component/VideoPlayer';
import { UserDefaultVal } from '../../UserContextVal';


function Content01_M() {
  const { t, loadingVal } = useContext(UserDefaultVal);
  const [isPlayer, setIsPlayer] = useState(false);

  const playerOnOff = () => {
    setIsPlayer(current => current = !isPlayer);
  }

  const bodyElm  = document.querySelector('body') as HTMLElement;

  useEffect(() => {
    if(isPlayer) {
      bodyElm.style.overflow = 'hidden';
    } else {
      bodyElm.style.removeProperty('overflow');
    }

  }, [isPlayer])


  return (
    <>
      <Loading isPlayer={isPlayer} />
      <VideoPlayer isPlayer={isPlayer} playerOnOff={playerOnOff} />
      <div className={styles.container}>
        <div className={`${styles.contentWrap} ${loadingVal ? styles.active : ''}`}>
          <div 
            className={styles.titleBox} 
          >
            <h2 className={styles.cnt1_ani_t01}>The World</h2>
            <h2 className={styles.cnt1_ani_t02}>We Meet Again</h2>
            <h2 className={styles.cnt1_ani_t03}>By SUPERNOVA</h2>
          </div>
          <div 
            className={styles.button} 
            onClick={()=> playerOnOff()}
          >
            <p className={styles.cnt1_ani_t04}>Show Video</p>
            <div className={styles.buttonIco}>
                <div className={styles.bIcoWrap01}>
                  <span className={styles.bIco01}></span>
                  <span className={styles.bIco02}></span>
                </div>
            </div>
          </div>
          <p className={styles.desc}>{t('cont04_desc_01')}</p>
        </div>
      </div>
    </>
  );
}

export default Content01_M;



