import React, { useState, useEffect, useRef, useContext, useMemo }  from 'react';
import styles from '../styles/Main_M.module.css';
import Nav_M from './Nav_M';
import Menu_M from './Menu_M';
import Content01_M from './Content01_M';
import Content02_M from './Content02_M';
import Content03_M from './Content03_M';
import Content04_M from './Content04_M';
import Content05_M from './Content05_M';
import Content06_M from './Content06_M';
import { UserDefaultVal, MobileDefaultVal } from '../../UserContextVal';

function Main_M() {
  const { loadingVal } = useContext(UserDefaultVal);

  const [ratioVal, setRatioVal] = useState(0);
  const [menuOnOff, setMenuOnOff]:any = useState(false); 
  const ctnElm:any = useRef();
  const ctn03Ref:any = useRef(null);
  const ctn04Ref:any = useRef(null);
  const ctn05Ref:any = useRef(null);
  const ctn06Ref:any = useRef(null);


  const playAniClass = (elm: any) => {
    if(loadingVal) {
      let elmInfo = elm.current.getBoundingClientRect();
      const partScrollStart = elmInfo.top - window.innerHeight;
      // removeclass 필요시
      // const partScrollEnd = -(elmInfo.height + ((window.innerHeight / 6) * 5));
      let returnVal;
      if(0 >= partScrollStart) {
        returnVal = true;
      } else {
        returnVal = false;
      }
      return returnVal;
    }
    
}

  const menuFtn = () => {
    setMenuOnOff((current: any) => current = !current);
  }

  const scrollMove = (name:string) => {
    let elmMoveY = 0;
    
    if(name === 'about') {
      const elmMoveY = 
        ctnElm.current.clientHeight - 
        (
          ctn03Ref.current.clientHeight + 
          ctn04Ref.current.clientHeight + 
          ctn05Ref.current.clientHeight + 
          ctn06Ref.current.clientHeight
        );
      window.scroll({ top: elmMoveY, left: 0, });
      menuFtn();
    } else if (name === 'reference') {
      const elmMoveY = 
        ctnElm.current.clientHeight - 
        (
          ctn05Ref.current.clientHeight + 
          ctn06Ref.current.clientHeight
        );
      window.scroll({ top: elmMoveY, left: 0, });
      menuFtn();
    } else if (name === 'exprience') {
      const elmMoveY = ctnElm.current.clientHeight - ctn06Ref.current.clientHeight;
      window.scroll({ top: elmMoveY, left: 0, });
      menuFtn();
    } else if (name === 'top') {
      elmMoveY = 0;
      window.scroll({ top: elmMoveY, left: 0, behavior: 'smooth' });
      if(menuOnOff) {
        window.scroll({ top: elmMoveY, left: 0, });
        menuFtn();
      } 
    }
  }
  
  useEffect(() => {

    const onScroll = () => {
      if (loadingVal) {
        setRatioVal((current) => {
          current = Number(((window.pageYOffset / (ctnElm.current.clientHeight - window.innerHeight)) * 100).toFixed(2));
          return current;
        });
      };
    };
    
    window.addEventListener('scroll', onScroll);

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    
  },[loadingVal])

  const value:any = useMemo(() => ({ 
    menuOnOff,
    ratioVal,
    playAniClass,
    scrollMove,
    menuFtn,
  }), [menuOnOff, ratioVal, playAniClass, scrollMove, menuFtn]);


  return (
    <div 
      className={`${styles.container}`}
      style={{
        overflow: !menuOnOff ? 'auto' : 'hidden', 
      }}
      ref={ctnElm}
    >
      <MobileDefaultVal.Provider value={value}>
        <Menu_M />
        <Nav_M />
        <Content01_M />
        <Content02_M />
        <Content03_M ref={ctn03Ref} />
        <Content04_M ref={ctn04Ref} />
        <Content05_M ref={ctn05Ref} />
        <Content06_M ref={ctn06Ref} />
      </MobileDefaultVal.Provider>      
    </div>
  );
}

export default Main_M;
