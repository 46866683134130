import React, { useContext }  from 'react';
import styles from '../styles/Swiper_item_M.module.css';
import { UserDefaultVal } from '../../UserContextVal';

function Swiper_item_M({index, info}:any) {
  const { i18n } = useContext(UserDefaultVal);

  const onTouch = (e:any) => {
    const targetElm = e.currentTarget;
    targetElm.classList.toggle('mobileSwiperItem');
    setTimeout(() => {
      targetElm.classList.remove('mobileSwiperItem');
    }, 4000)
  }

  return (
  <div className={styles.container}>
    {info[index].map((content:any, index:any) => (
      <div 
        key={index}
        className={`item_m item_m_${index}`}
      >
        <div 
          className={`item_inner_m`}
          style={{background: content.background}}
          onTouchStart={(e) => {onTouch(e)}}
        >
          <div className='swi_logoWrap_m'>
            <img src={require(`../../assets/reference_logo/${content.logo}`)} alt="logo" />
          </div>
          <div className='swi_innerText_m'>
            {i18n.language === 'ko' ?
              <p>{content.description_kr}</p>
              :
              <p>{content.description_en}</p>
            }
          </div>
        </div>
      </div>
    ))}
  </div>
  );
}

export default Swiper_item_M;
