import React, { useState, useContext }  from 'react';
import styles from '../styles/Nav_PC.module.css';
import LogoSvg from '../../common/component/LogoSvg';
import { UserDefaultVal, PcDefaultVal } from '../../UserContextVal';

function Nav_PC() {
  const { soundVal, soundOnOff, loadingVal, i18n } = useContext(UserDefaultVal);
  const { contentInfo, playAnimation, menuFtn, movePos } = useContext(PcDefaultVal);
  const values = contentInfo[0].values;
  const aniVal1 = playAnimation(values.contentAni01);
  const aniVal2 = playAnimation(values.contentAni02); 
  const aniVal3 = playAnimation(values.contentAni03); 
  const valSum = aniVal1 + aniVal2 + aniVal3;
  let classVal = false;


  if(valSum === 1) {
    classVal = true;
  } else if(valSum === 2) {
    classVal = false;
  } else if(valSum === 3) {
    classVal = true;
  };

  return (
    <div 
      className={`${styles.container} ${classVal ? styles.darkTheme : styles.lightTheme}`} 
      style={{width: `${contentInfo[0].contentWidth}px`, opacity: loadingVal ? 1 : 0}}
    >
      <header className={styles.header}>
        <div className={styles.contentLeft}>
          <div className={styles.menu} onClick={menuFtn}>
            <span className={styles.m01}></span>
            <span className={styles.m02}></span>
            <span className={styles.m03}></span>
          </div>
          <div onClick={() => movePos('goMain')} className={styles.logo}>
            <LogoSvg classVal={classVal} loadingVal={loadingVal}/>
          </div>
        </div>
        <div className={styles.contentRight}>
          <div className={styles.language}>
            <div
              className={styles.kr} 
              style={{opacity: `${ i18n.language === 'ko' ? '1' : '0.6'}`}}
              onClick={() => i18n.changeLanguage('ko')}
            >
                KR
            </div>
            <div 
              className={styles.en} 
              style={{opacity: `${i18n.language === 'ko' ? '0.6' : '1'}`}}
              onClick={() => i18n.changeLanguage('en')}
            >
              EN
            </div>
          </div>
          <div className={`${styles.sound} ${soundVal ? 'activeSound' : undefined}`} onClick={soundOnOff}>
            <span className={`${styles.s01} ${'s01'}`}></span>
            <span className={`${styles.s02} ${'s02'}`}></span>
            <span className={`${styles.s03} ${'s03'}`}></span>
            <span className={`${styles.s04} ${'s04'}`}></span>
            <span className={`${styles.s05} ${'s05'}`}></span>
          </div>
        </div>
      </header>
      <footer className={styles.footer}>
        <p>COPYRIGHT SK TELECOM. ALL RIGHTS RESERVED.</p>
      </footer>
    </div>
  );
}

export default Nav_PC;
