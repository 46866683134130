import React, { useState, useContext }  from 'react';
import styles from '../styles/Nav_M.module.css';
import LogoSvg from '../../common/component/LogoSvg';
import { UserDefaultVal, MobileDefaultVal } from '../../UserContextVal';

function Nav_M() {
  const { soundVal, soundOnOff, loadingVal } = useContext(UserDefaultVal);
  const { ratioVal, menuFtn, scrollMove } = useContext(MobileDefaultVal);

  let classVal = false;

  if(ratioVal >= 13.1 && ratioVal < 22.8) {
    classVal = true;
  } else if(ratioVal >= 22.8 && ratioVal < 73.94) {
    classVal = false;
  } else if(ratioVal >= 73.94) {
    classVal = true;
  };


  return (
    <div 
      className={`${styles.container} ${classVal ? styles.darkTheme : styles.lightTheme}`} 
      style={{opacity: loadingVal ? 1 : 0}}
    >
      <header className={styles.header}>
        <div className={styles.contentLeft}>
          <div className={styles.menu} onClick={menuFtn}>
            <span className={styles.m01}></span>
            <span className={styles.m02}></span>
            <span className={styles.m03}></span>
          </div>
          <div className={styles.logo} onClick={() => scrollMove('top')}>
            <LogoSvg classVal={classVal}/>
          </div>
        </div>
        <div className={styles.contentRight}>
          <div className={`${styles.sound} ${soundVal ? 'activeSound' : undefined}`} onClick={soundOnOff}>
            <span className={`${styles.s01} ${'s01'}`}></span>
            <span className={`${styles.s02} ${'s02'}`}></span>
            <span className={`${styles.s03} ${'s03'}`}></span>
            <span className={`${styles.s04} ${'s04'}`}></span>
            <span className={`${styles.s05} ${'s05'}`}></span>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Nav_M;
