import React, { useState, useEffect, useRef, useContext }  from 'react';
import styles from '../styles/Loading.module.css';
import VideoWebm from '../../assets/video/main-video.webm';
import VideoMp4 from '../../assets/video/main-video.mp4';
import VideoWebm_M from '../../assets/video/main-video-mobile.webm';
import VideoMp4_M from '../../assets/video/main-video-mobile.mp4';
import Poster from '../../assets/img/loading_poster.jpg';
import LogoSvg from './LogoSvg';
import Video from './Video';
import { UserDefaultVal } from '../../UserContextVal';


function Loading({isPlayer}:any) {
  const { loadingVal, setLoadingVal, soundOnOff, deviceVal, setSoundVal } = useContext(UserDefaultVal);
  const [isDelay, setIsDelay] = useState(true);
  const videoRef:any = useRef();


  useEffect(() => {

    if (isPlayer === true && loadingVal === true) {
      videoRef.current.pause();
      setSoundVal((current:any) => current = false);
    } else if (isPlayer === false && loadingVal === true) {
      videoRef.current.play();
      soundOnOff();
    }

  },[isPlayer]) 

  useEffect(() => {
    setTimeout(() => {
      setIsDelay((current:any) => current = false);
    }, 2500)
  },[])

  const videoRender = () => {
    if (deviceVal === 'PC') {
      return <Video VideoWebm={VideoWebm} VideoMp4={VideoMp4} ref={videoRef} />  
    } else if (deviceVal === 'Moboile') {
      return <Video VideoWebm={VideoWebm_M} VideoMp4={VideoMp4_M} ref={videoRef} Poster={Poster} />
    }
  }


    return (
      <div 
        className={`${styles.container} ${loadingVal ? styles.active : ''}`}
        style={{pointerEvents: `${loadingVal ? 'none' : 'initial'}`}}
      >
          <div 
            className={styles.logoWrap}
            onClick={() => {
              if(isDelay) {
                setLoadingVal((current:any) => current = false);
              } else {
                setLoadingVal((current:any) => current = true);
                soundOnOff();
              }
            }}
          >
            <div className={styles.logoMasking}>
              <div className={styles.logo}>
                <LogoSvg loadingVal={loadingVal}/>
              </div>
            </div>
            <div className={styles.text}>
              <span>Click To Explore</span>
            </div>
          </div>
        <div className={styles.masking}>
          <div className={styles.videoWrap}>
            {videoRender()}
          </div>
        </div>
      </div>
    );
}

export default Loading;
