import React, { useState, useRef, useEffect, useContext, useCallback }  from 'react';
import styles from '../styles/Content02.module.css';
import Video from '../../common/component/Video';
import VideoA01 from '../../assets/video/after.webm';
import VideoA02 from '../../assets/video/after.mp4';
import VideoB01 from '../../assets/video/before.webm';
import VideoB02 from '../../assets/video/before.mp4';
import HandlerSvg from '../../assets/icons/handler.svg';
import { UserDefaultVal,  PcDefaultVal } from '../../UserContextVal';



function Contnet02() {
  const {t} = useContext(UserDefaultVal);
  const { contentInfo, playAnimation, ratioVal } = useContext(PcDefaultVal);
  const [mouseOver, setMouseOver] = useState(false);
  const [clientX, setClientX]:any = useState();
  const [fsOffsetX, setFsOffsetX] = useState(0);

  useEffect((() => {
    const setLayoutFS = () => {
      setFsOffsetX(current => current = Math.min(Math.max(window.innerWidth * 0.26041, 323), 666.75));
    }
    setLayoutFS();

  }),[])

  const MouseEvent = (e:any) => {
    setMouseOver(true);
    setClientX((current:any) => {
      current = e.nativeEvent.offsetX;
      return current;
    });
  };

  const vidARef:any = useRef(null);
  const vidBRef:any = useRef(null);

  const values = contentInfo[2].values;
  let contentAni01_h1 = 0;
  let contentAni02_h1 = 0;
  let contentAni01_t = 0;
  let contentAni01_img_opa =0;

  if (ratioVal >= 0 && ratioVal <= 32) {
    contentAni01_h1 = playAnimation(values.contentAni01_h1);
    contentAni02_h1 = playAnimation(values.contentAni02_h1);
    contentAni01_t = playAnimation(values.contentAni01_t);
    contentAni01_img_opa = playAnimation(values.contentAni01_img_opa);
  }

  useEffect(() => {

    if(vidARef.current.currentTime - vidBRef.current.currentTime > 0.05) {
      vidARef.current.currentTime = vidBRef.current.currentTime;
      vidBRef.current.currentTime = vidARef.current.currentTime;
      vidARef.current.play();
      vidBRef.current.play();
    } 
    
  },[clientX, ratioVal])

  return (
    <div className={styles.container} style={{ width: `${contentInfo[2].contentWidth}px`}}>
      <div className={styles.content02}> 
        <div className={styles.contentWrap}>
          <div className={styles.titleBox}>
            <h2 style={{ opacity: `${contentAni01_h1}` }}>{t('cont02_title_01_01')}</h2>
            <h2 style={{ opacity: `${contentAni02_h1}` }}>{t('cont02_title_01_02')}</h2>
          </div>
          <div className={styles.subText} style={{ opacity: `${contentAni01_t}` }}>
            <p>{t('cont02_desc_01')}</p>
          </div>
        </div>
        <div 
          className={styles.assetsWrap} 
          onMouseMove={MouseEvent} 
          id='mouseElm'
          style={{opacity: `${contentAni01_img_opa}`}}
        >
          <div 
            className={styles.handlerLine}
            style={{left: `${(mouseOver) ? clientX : fsOffsetX}px`}}
          >
            <span className={styles.handlerIco}>
              <img src={HandlerSvg} alt="ico" />
            </span>
          </div>
            <div className={styles.afterVideo}>
              <Video VideoWebm={VideoA01} VideoMp4={VideoA02} ref={vidARef} />
            </div>
            <div 
              className={styles.beforeVideo}
              style={{clip: `rect(0, 10000000px, 10000000px, ${(mouseOver) ? clientX : fsOffsetX}px)`}}
            >
              <Video VideoWebm={VideoB01} VideoMp4={VideoB02} ref={vidBRef}/>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Contnet02;
