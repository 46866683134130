import React, { useState, useContext }  from 'react';
import styles from '../styles/Content06.module.css';
import Webimage from '../../assets/img/exprience_01.png';
import Appimage from '../../assets/img/exprience_02.png';
import GoogleIcon from '../../assets/icons/android.svg';
import IosIcon from '../../assets/icons/ios.svg';
import { UserDefaultVal,  PcDefaultVal } from '../../UserContextVal';

function Contnet06() {
  const {t} = useContext(UserDefaultVal);
  const { contentInfo, playAnimation, ratioVal, movePos } = useContext(PcDefaultVal);
  const values = contentInfo[6].values;
  const cnt06 = {
    aniVal01_img_sc : 0,
    aniVal01_img_m : 0,
    aniVal01_img_opc : 0,
    aniVal01_t_m : 0,
    aniVal01_t : 0,
    aniVal01_2_t : 0,
    aniVal02_img_sc : 0,
    aniVal02_img_m : 0,
    aniVal02_img_opc : 0,
    aniVal02_t_m : 0,
    aniVal02_t : 0,
    aniVal02_2_t : 0,
    aniVal03_ico : 0,
    classActive : false,
  };

  if(ratioVal >= 80.74 && ratioVal <= 100) {

    cnt06.aniVal01_img_sc = playAnimation(values.contentAni01_img_sc);
    cnt06.aniVal01_img_m = playAnimation(values.contentAni01_img_m);
    cnt06.aniVal01_img_opc = playAnimation(values.contentAni01_img_opc);
    cnt06.aniVal01_t_m = playAnimation(values.contentAni01_t_m);
    cnt06.aniVal01_t = playAnimation(values.contentAni01_t);
    cnt06.aniVal01_2_t = playAnimation(values.contentAni01_2_t); 
    cnt06.aniVal02_img_sc = playAnimation(values.contentAni01_img_sc);
    cnt06.aniVal02_img_m = playAnimation(values.contentAni01_img_m);
    cnt06.aniVal02_img_opc = playAnimation(values.contentAni01_img_opc);
    cnt06.aniVal02_t_m = playAnimation(values.contentAni01_t_m);
    cnt06.aniVal02_t = playAnimation(values.contentAni01_t);
    cnt06.aniVal02_2_t = playAnimation(values.contentAni01_2_t); 
    cnt06.aniVal03_ico = playAnimation(values.contentAni03_ico);

    if(cnt06.aniVal03_ico === 1) {
      cnt06.classActive = true;
    } else {
      cnt06.classActive = false;
    }

  }


  return (
    <div className={styles.container} style={{width: `${contentInfo[6].contentWidth}px`}}>
      <div className={styles.webContainer}>
        <div 
          className={styles.webImgWrap} 
          style={{ 
            opacity: `${cnt06.aniVal01_img_opc}`, 
            transform: `scale(${1 + cnt06.aniVal01_img_sc}) translate3d(0, -${8 * cnt06.aniVal01_img_m}%, 0)`
          }}
        >
          <img src={Webimage} alt="웹사이트 예시 이미지" />
        </div>
        <div style={{ transform: `translate3d(0, ${20 * cnt06.aniVal01_t_m}%, 0)` }}>
          <div 
            className={styles.webTitle} 
            style={{ opacity: `${cnt06.aniVal01_t}` }}
          >
            <h2>{t('cont06_title_01')}</h2>
          </div>
          <div 
            className={styles.webDesc} 
            style={{ opacity: `${cnt06.aniVal01_2_t}` }}
          >
            <p>{t('cont06_desc_01_01')}</p>
            <p>
              {t('cont06_desc_01_02')}&nbsp;
              <a href="mailto:supernova-prd@sktelecom.com">{t('cont06_desc_01_03')}</a>
              {t('cont06_desc_01_04')}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.appContainer}>
        <div style={{ transform: `translate3d(0, -${20 * cnt06.aniVal02_t_m}%, 0)` }}>
          <div 
            className={styles.appTitle} 
            style={{ opacity: `${cnt06.aniVal02_t}` }}
          >
            <h2>{t('cont06_title_02')}</h2>
          </div>
          <div 
            className={styles.appDesc} 
            style={{ opacity: `${cnt06.aniVal02_2_t}` }}
          >
            <p>{t('cont06_desc_02')}</p>
          </div>
          <div 
            className={styles.appBtnWrap}
            style={{ opacity: `${cnt06.aniVal02_2_t}` }}
          >
            <button className={styles.androidBtn}>
              <img src={GoogleIcon} alt="구글 스토어 아이콘" />
              <p>Android</p>
            </button>
            <button className={styles.iosBtn}>
              <img src={IosIcon} alt="애플 스토어 아이콘" />
              <p>IOS</p>
            </button>
          </div>
        </div>
        <div 
          className={styles.appImgWrap} 
          style={{ 
            opacity: `${cnt06.aniVal02_img_opc}`, 
            transform: `scale(${1 + cnt06.aniVal02_img_sc}) translate3d(0, ${8 * cnt06.aniVal02_img_m}%, 0)`
          }}
        >
          <img src={Appimage} alt="앱 예시 이미지" />
        </div>
      </div>
      <div className={`${styles.goMainContainer} ${cnt06.classActive ? styles.active : ''}`} onClick={() => movePos('goMain')}>
        <p className={`${styles.goMainText} ${cnt06.classActive ? styles.activeTxt : ''}`}>GO Main</p>
        <div 
          className={`${styles.arrowIco01} ${cnt06.classActive ? styles.activeIco : ''}`}
        >
          <span className={styles.arrow01}></span>
          <span className={styles.arrow02}></span>
        </div>
      </div>
      <div className={styles.blurWrap}></div>
    </div>
  );
}

export default Contnet06;
